body {
  background-color: #000;
  background-position: center top;
  background-image: url('./background.jpg');
  color: #888;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.play .MuiButtonBase-root.Mui-disabled {
  color: #417222;
}

header {
  /* background-color: #444444; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
  /* color: #5544CC; */
}

header .about, header .logo {
  color: #5544CC;
  font-weight: bold;
  line-height: 36px;
  padding: 0px 10px;
}

label {
  font-size: 0.6em;
}

/* -------------------- TRACK ------------------ */

.tracks {
  color: #999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  min-width: 350px;
}

.track {
  background: #222222;
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 66px;
  margin-left: 2px;
  margin-right: 2px;
}

canvas {
  background: #222222;
  margin-top: 4px;
}

.track0 .color {
  background: rgba(200, 50, 0, 1);
  height: 1px;
  width: 100%;
}

.track1 .color {
  background: rgba(200, 150, 0, 1);
  height: 1px;
  width: 100%;
}

.track2 .color {
  background: rgba(150, 200, 0, 1);
  height: 1px;
  width: 100%;
}

.track3 .color {
  background: rgba(100, 150, 255, 1);
  height: 1px;
  width: 100%;
}

.track4 .color {
  background: rgba(200, 100, 255, 1);
  height: 1px;
  width: 100%;
}

/* ------------------------------------------------------------------ */

.loading0 {
  -webkit-animation: loading0 0.2s infinite;
  /* Safari 4+ */
  -moz-animation: loading0 0.2s infinite;
  /* Fx 5+ */
  -o-animation: loading0 0.2s infinite;
  /* Opera 12+ */
  animation: loading0 0.2s infinite;
  /* IE 10+, Fx 29+ */
}

@keyframes loading0 {
  0% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: rgba(200, 50, 0, 1);
  }
}

/* ------------------------------------------------------------------ */

.loading1 {
  -webkit-animation: loading1 0.2s infinite;
  /* Safari 4+ */
  -moz-animation: loading1 0.2s infinite;
  /* Fx 5+ */
  -o-animation: loading1 0.2s infinite;
  /* Opera 12+ */
  animation: loading1 0.2s infinite;
  /* IE 10+, Fx 29+ */
}

@keyframes loading1 {
  0% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: rgba(200, 150, 0, 1);
  }
}

/* ------------------------------------------------------------------ */

.loading2 {
  -webkit-animation: loading2 0.2s infinite;
  /* Safari 4+ */
  -moz-animation: loading2 0.2s infinite;
  /* Fx 5+ */
  -o-animation: loading2 0.2s infinite;
  /* Opera 12+ */
  animation: loading2 0.2s infinite;
  /* IE 10+, Fx 29+ */
}

@keyframes loading2 {
  0% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: rgba(150, 200, 0, 1);
  }
}

/* ------------------------------------------------------------------ */

.loading3 {
  -webkit-animation: loading3 0.2s infinite;
  /* Safari 4+ */
  -moz-animation: loading3 0.2s infinite;
  /* Fx 5+ */
  -o-animation: loading3 0.2s infinite;
  /* Opera 12+ */
  animation: loading3 0.2s infinite;
  /* IE 10+, Fx 29+ */
}

@keyframes loading3 {
  0% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: rgba(100, 150, 255, 1);
  }
}

/* ------------------------------------------------------------------ */

.loading4 {
  -webkit-animation: loading4 0.2s infinite;
  /* Safari 4+ */
  -moz-animation: loading4 0.2s infinite;
  /* Fx 5+ */
  -o-animation: loading4 0.2s infinite;
  /* Opera 12+ */
  animation: loading4 0.2s infinite;
  /* IE 10+, Fx 29+ */
}

@keyframes loading4 {
  0% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: rgba(200, 100, 255, 1);
  }
}

.track .MuiSelect-icon {
  display: none;
}

.track .setFile .MuiSelect-select.MuiSelect-select, .track .setFile .MuiSelect-select {
  padding: 0px;
  padding-top: 20px;
}

.track .buttons {
  padding-bottom: 20px;
}

.track .volume {
  padding-top: 10px;
  padding-bottom: 10px;
}

.track .slider {
  height: 220px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiSlider-root.MuiSlider-vertical {
  height: 220px !important;
}

button.MuiIconButton-root.Mui-disabled {
  color: #333;
}

.controls {
  padding-top: 10px;
  padding-bottom: 10px;
}

.label {
  font-size: 0.8em;
  color: #417222
}

.volume {
  font-size: .7em;
  padding-bottom: 20px;
}

.track .buttons {
  color: #ccc;
}

.knob label {
  color: #666666;
  font-size: 0.7em;
}

.knob {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.presets {
  display: flex;
  justify-content: center;
}

.presets .prompt {
  display: flex;
  padding-top: 5px;
}

.presets .select {
  display: flex;
  background: #000000;
  min-width: 100px;
}

.presets .select .MuiInputBase-root {
  background: #000000;
  min-width: 100px;
}

.setFile .MuiSelect-select {
  font-size: 10px;
  max-width: 60px;
  white-space: normal;
  height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 5px;
  line-height: 12px;
  color: #cccccc;
}

/* -------------------- FOOTER ------------------ */

#footer {
  color: #888888;
  font-size: 0.7em;
}

#footer .bottom {
  color: #666666;
  font-size: 0.9em;
}

#footer .warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

#footer .warningWrapper {
  /* background: #555; */
  display: flex;
  flex-direction: row;
  width: 280px;
}

#footer .warningText {
  line-height: 14px;
  font-size: 14px;
  margin-top: 5px;
  font-size: 12px;
}

#footer svg.warningIcon {
  /* color: green; */
  margin: 0px;
  padding: 0px;
  height: 38px;
  font-size: 32px;
  line-height: 16px;
}

#footer a.badge img {
  width: 186px;
}

g#labeltext, text#ae85323a-4de4-34dc-f124-25c714280764, tspan, text.text-7 {
  color: #000000 !important;
  /* font-size: 33px; */
  text-shadow: 0px 0px 0px #000;
}

/*   ABOUT    */

button.aboutButton {
  color: #7766EE;
}

svg.aboutIcon {
  line-height: 10px;
  margin-right: 15px;
  font-size: 1.2em;
}

svg.aboutLinkIcon {
  margin-left: 5px;
  font-size: 1em;
  color: #9988FF;
}

p.aboutList, p.aboutText {
  font-size: 13px;
  padding: 0px 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  body {
    background-color: #000;
    background-image: none;
  }
  .track .setFile .MuiSelect-select {
    padding: 0px;
    padding-top: 1px;
  }
  /* SLIDER */
  .track .slider {
    height: calc(100vh - 540px);
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MuiSlider-root.MuiSlider-vertical {
    height: calc(100vh - 540px) !important;
  }
  /* PLAY - STOP */
  .track .controls {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .track .controls button {
    padding: 5px;
  }
  /* SPEED - DEPTH */
  .track .buttons {
    padding-bottom: 5px;
  }
  .track .buttons .knob {
    padding-bottom: 5px;
  }
}